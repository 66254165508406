button.lbp {
  width: 40%;
  height: 50px;
  background: #66d8e5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 33px;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border-style: hidden;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
}

button.lbp.auto {
  width: auto;
  height: auto;
  padding: 0.8rem;
}

button.lbp.auto.white {
  color: #6eaf95;
  background-color: white;
}

button.lbp.auto.small {
  width: auto;
  height: auto;
  padding: 0 0.8rem;
}

button:disabled {
  background: grey 0% 0% no-repeat padding-box;
  opacity: 0.7;
  cursor: not-allowed;
}

#method-select-vehicle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  padding: 0 4rem;
}

#container-immat {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#container-immat > label {
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: #6eaf95;
}

#container-immat > input {
  line-height: 3rem;
  width: 100%;
}

#container-immat > input {
  width: 80%;
  background-image: url("../images/plaque.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 2.3rem;
  line-height: 3rem;
  padding-left: 1em;
  font-size: 3rem;
  text-align: center;
  border-radius: 0.25rem;
  margin: 3rem auto;
}

.tank {
  width: 200px;
}

.centring {
  width: 100%;
  justify-items: center;
  text-align: center;
}

.centring a {
  color: #96b6b9;
}

.ctn {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  clip-path: circle(
    150% at calc(50% - 0px - var(--page-component-margin, 0px))
      calc(90% + var(--page-component-margin, 0px))
  );
}

#svg {
  width: 100%;
  height: 90%;
  max-height: 500px;
}

p {
  text-align: left;
  /* font: normal normal 600 24px/32px Segoe UI; */
  letter-spacing: 0px;
  color: #003a40;
}

#selector {
  margin-top: 120px;
}

#selectMarque {
  width: 60%;
  height: 53px;
  margin-right: 20%;
  margin-left: 20%;
  text-align-last: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #336166;
  border-radius: 25px;
  opacity: 1;
  text-align: left;
  /* font: normal normal 600 24px/32px Segoe UI; */
  letter-spacing: 0px;
  color: #003a40;
  opacity: 0.66;
}

#bouton_valider {
  margin: 12px auto;
}

#logo {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

nav {
  padding-top: 2vh;
  margin-bottom: 7px;
}

#backArrow {
  position: absolute;
  padding-left: 15px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#infos {
  position: absolute;
  padding-left: 15px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

/* Modal partage */

/* The Modal (background) */

.modal {
  display: none;
  /* Hidden by default */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/ opacity */
}

/* Modal Content */

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 80%;
  height: 400px;
  border-radius: 30px;
  box-shadow: 0px 3px 6px #00000029;
}

.modal-content h6 {
  margin-bottom: 30px;
  text-align: left;
  /* font: normal normal normal 24px/32px Segoe UI; */
  font-size: 32px;
  letter-spacing: 0px;
  color: #336166;
  opacity: 1;
}

.modal-content p {
  margin-bottom: 25px;
  color: #7f9b9e;
}

/* The Close Button */

.close,
.closeInfos {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus,
.closeInfos:hover,
.closeInfos:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#error-immat .close {
  position: absolute;
  top: 5;
  right: 5;
}
/* Menu de partage */

.modal-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* .modal-content ul::-webkit-scrollbar {
    display: none;
} */

.modal-content li {
  margin-left: 2px;
  display: inline-block;
  /*pour IE*/
}

.modal-content input {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 50px;
  border-radius: 30px 30px 30px 30px;
  padding-left: 20px;
  font-size: 18px;
}

.modal-content button {
  width: 100%;
  margin: 0 0 0 0 !important;
}

.modal-content div {
  display: flex;
  width: 100%;
  margin: 0 0 1em 0;
}

.modal-content ul li img {
  width: 130px;
}

.modal-content input {
  flex-grow: 1;
  width: 450px;
  border: 0px;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.modal-content button {
  margin-left: 10px;
  padding: 0 1em;
  width: 40%;
  margin-left: -60px !important;
}

.modal-content div {
  padding-top: 40px;
}

#copy {
  font-size: 18px !important;
}

p.partage {
  text-align: center !important;
  font-size: 24px !important;
}

.modal-content a {
  color: inherit;
  font: inherit;
}

#footer {
  text-align: center;
  font-size: medium;
  opacity: 66%;
  /* position: absolute; */
  /* bottom: 10px; */
  /* margin-left: calc((100% - 223px) / 2); */
}

.container-filter {
  display: flex;
  justify-content: space-around;
}

#input_keyword {
  text-align: center;
}

#noresult {
  text-align: center;
}

#info-localisation {
  font-size: 0.8rem;
  padding: 1rem;
}

#distance_form button#cu-pos-btn {
  width: 85%;
  background-color: #003a40;
  height: 2rem;
  font-weight: 600;
  color: white;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 4px;
  font-size: 12px;
  border-radius: 13px;
  border: none;
  cursor: pointer;
}

.resultRVL h5 {
  text-align: center !important;
  /* font: normal normal normal 11px/31px Segoe UI !important; */
  letter-spacing: 0px !important;
  color: #ffffff;
  font-size: 1.25rem;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}

.card-result {
  background-color: white;
  border: solid 3px #6eaf95;
  border-radius: 13px;
  margin: 0.5rem;
  padding: 0.5rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  color: #526b5c;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
}

.card-result table {
  width: 100%;
}

#title-result {
  color: #6eaf95;
  margin-bottom: 0.8rem;
  font-size: 1.25rem;
}

.card-result th {
  text-align: left;
}
.card-result td {
  text-align: right;
  padding-left: 0.5rem;
}

dialog#error-immat,
dialog#result-vehicule {
  padding: 2rem;
  margin: 2rem;
  margin-right: auto;
  margin-left: auto;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #6eaf95;
  border-radius: 13px;
  top: 47%;
  transform: translateY(-50%);
}

dialog#result-vehicule {
  padding: 0rem;
  margin: 2rem auto;
  line-height: 1rem;
  width: 90%;
}

dialog#result-vehicule .card-result {
  border: none;
}

dialog button {
  margin-top: 0.5rem;
}

@media (max-width: 500px) {
  #logo {
    height: 10vh;
  }
  .modal {
    position: fixed !important;
  }
  .ctn {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  #result,
  #etapes,
  #home {
    width: 100%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    clip-path: circle(
      150% at calc(50% - 0px - var(--page-component-margin, 0px))
        calc(90% - 70px + var(--page-component-margin, 0px))
    );
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #footer {
    flex-grow: 1;
  }

  #input_keyword {
    font-size: 0.8rem;
  }

  #container-immat > input {
    font-size: 2.5rem;
  }

  #divsvg {
    height: calc(100% - 133px - 13vh - 17vh);
    margin-left: auto;
    margin-right: auto;
    padding-left: 12%;
  }
  #result {
    height: calc(100% - 90px);
    background-color: #66d8e6;
    /* animation: circle 1.5s; */
    position: relative;
  }

  .resultRVL h3 {
    margin-top: 20%;
  }

  .resultRVL h5 {
    text-align: center !important;
    /* font: normal normal normal 11px/31px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: #003a40;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  #Autho {
    width: 85%;
  }

  #cross {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60px;
    cursor: pointer;
  }
  @keyframes circle {
    0% {
      clip-path: circle(
        3% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
    15% {
      clip-path: circle(
        10% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
    30% {
      clip-path: circle(
        5% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
    100% {
      clip-path: circle(
        150% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
  }
  #partager {
    width: 300px;
    height: 50px;
    margin-right: calc((100% - 300px) * 0.5) !important;
    margin-left: calc((100% - 300px) * 0.5) !important;
    margin-top: 10vh;
    background: #96b6b9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px !important;
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    font-size: 24px !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-style: hidden;
    cursor: pointer;
  }
  #bouton_go {
    width: 100px;
    height: 60px;
    background: #66d8e5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px !important;
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    font-size: 32px !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-style: hidden;
    margin-left: auto !important;
    margin-right: auto !important;
    /* margin-top: 10vh;
    margin-right: calc((100% - 100px) * 0.5) !important;
    margin-left: calc((100% - 100px) * 0.5) !important; */
  }
  h1 {
    text-align: center;
    /* font: normal normal 600 48px/36px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40;
    opacity: 0.8;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40;
    opacity: 0.8;
    margin-bottom: 7px !important;
    width: 85%;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.4rem;
  }
  h3 {
    text-align: center !important;
    /* font: normal normal bold 72px/64px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: #ffffff !important;
    font-size: 6vh !important;
    line-height: 6vh !important;
    font-weight: bold !important;
    margin-bottom: 5px !important;
  }
  h3.welcome2 {
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40 !important;
    opacity: 0.8;
    font-size: 18px !important;
    font-weight: normal !important;
    margin-bottom: 16px !important;
    line-height: 32px !important;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  h4 {
    text-align: center !important;
    /* font: normal normal normal 11px/31px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: #ffffff !important;
    font-size: 20px !important;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  h5 {
    text-align: center !important;
    /* font: normal normal normal 48px/36px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: gray;
    font-size: 16px;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    /* margin-top: 40px; */
  }
  #welcome1 {
    width: 80%;
    font-size: 1.5rem;
    /* margin-top: 10vh; */
    /* margin-right: auto;
    margin-left: auto;
    margin-bottom: 0px !important; */
  }
  .welcome2 {
    margin-top: 1vh;
    width: 55%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0px !important;
  }

  button#bouton_valider {
    z-index: 10;
  }
  button {
    font-size: 24px;
  }

  button:disabled {
    font-size: 24px !important;
  }

  .feedback {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  .feedback li {
    display: inline-block;
    width: 49%;
  }
  .feedback button {
    font-size: 24px !important;
    border-radius: 10px !important;
    background: white;
    color: #66d8e5;
    opacity: 1;
  }
  #helpmsg {
    position: absolute;
    width: 20vw;
    bottom: 4vh;
    right: 1vw;
  }
  #helpmsg p {
    text-align: left;
    /* font: normal normal normal 20px/27px Segoe UI; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #66d8e5;
    opacity: 0.6;
  }
  .modal-content p {
    font-size: 5vw;
  }
}

@media (min-width: 500px) {
  #logo {
    height: 120px;
  }
  .modal {
    position: absolute;
  }
  #divsvg {
    width: 240px;
    margin-left: 151px;
    margin-right: 109px;
  }
  .ctn {
    width: 500px;
    /* height: 950px; */
    height: calc(100% - 100px);
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  #result,
  #etapes,
  #home {
    width: 500px;
    padding-bottom: 50px;
    margin-right: auto;
    margin-left: auto;
    /* position: absolute; */
    clip-path: circle(
      150% at calc(50% - 0px - var(--page-component-margin, 0px))
        calc(90% - 70px + var(--page-component-margin, 0px))
    );
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  #footer {
    flex-grow: 0;
  }
  .directions-card {
    display: none;
  }

  .directions-card-medium-large {
    display: none;
  }
  #result {
    background-color: #66d8e6;
    animation: circle 1.5s;
    height: 100%;
    padding-top: 100px;
    position: relative;
  }
  #cross {
    position: absolute;
    top: 21;
    right: 21px;
    width: 60px;
    cursor: pointer;
  }
  @keyframes circle {
    0% {
      clip-path: circle(
        3% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
    15% {
      clip-path: circle(
        10% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
    30% {
      clip-path: circle(
        5% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
    100% {
      clip-path: circle(
        150% at calc(50% - 0px - var(--page-component-margin, 0px))
          calc(90% - 70px + var(--page-component-margin, 0px))
      );
    }
  }
  #welcome1 {
    /* margin-top: 50px; */
    width: 75%;
    /* margin-right: auto;
    margin-left: auto; */
  }
  .welcome2 {
    margin-top: 13px;
    width: 55%;
    margin-right: auto;
    margin-left: auto;
  }
  #partager {
    width: 60%;
    height: 50px;
    margin-right: 20% !important;
    margin-left: 20% !important;
    margin-top: 150px;
    background: #96b6b9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px !important;
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    font-size: 24px !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-style: hidden;
    cursor: pointer;
  }
  #bouton_go {
    width: 20%;
    height: 60px;
    background: #66d8e5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px !important;
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    font-size: 32px !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border-style: hidden;
    /* margin-top: 50px;*/
    margin-right: auto;
    margin-left: auto;
  }

  #distance_form button#cu-pos-btn {
    width: 85%;
    background-color: #003a40;
    height: 2rem;
    font-weight: 600;
    color: white;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 4px;
    font-size: 1rem;
    border-radius: 13px;
    border: none;
    cursor: pointer;
  }
  h1 {
    text-align: center;
    /* font: normal normal 600 48px/64px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40;
    opacity: 0.8;
    /* font-size: 48px !important; */
    margin: auto;
    margin-bottom: 16px !important;
  }
  h2 {
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40;
    opacity: 0.8;
    font-size: 32px;
    margin-bottom: 16px !important;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  h3 {
    text-align: center !important;
    /* font: normal normal bold 72px/64px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: #ffffff;
    font-size: 72px !important;
    font-weight: bold !important;
    margin-bottom: 5px !important;
  }
  h3.welcome2 {
    text-align: center;
    /* font: normal normal normal 32px/43px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40 !important;
    opacity: 0.8;
    font-size: 24px !important;
    line-height: 36px !important;
    font-weight: normal !important;
    margin-bottom: 16px !important;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  h4 {
    text-align: center !important;
    /* font: normal normal normal 48px/48px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: #ffffff !important;
    font-size: 35px !important;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  h5 {
    text-align: center !important;
    /* font: normal normal normal 48px/36px Segoe UI !important; */
    letter-spacing: 0px !important;
    color: gray;
    font-size: 15px !important;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  button {
    font-size: 32px;
  }
  button:disabled {
    font-size: 32px;
  }

  .feedback {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  .feedback li {
    display: inline-block;
    width: 49%;
  }
  .feedback button {
    font-size: 24px !important;
    border-radius: 10px !important;
    background: white;
    color: #66d8e5;
    opacity: 1;
  }
  #helpmsg {
    position: absolute;
    width: 100px;
    bottom: 200px;
    right: 25px;
  }
  #helpmsg p {
    text-align: left;
    /* font: normal normal normal 20px/27px Segoe UI; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #66d8e5;
    animation: opacity 2s infinite;
    animation-timing-function: ease-in-out;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0.9;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.cls-1,
.cls-10,
.cls-5,
.cls-6 {
  fill: none;
}

.cls-2 {
  clip-path: url(#clip-path);
}

.cls-3 {
  clip-path: url(#clip-path-2);
}

.cls-4 {
  fill: #00bed4;
  opacity: 0.6;
}

.cls-12,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8 {
  isolation: isolate;
}

.cls-10,
.cls-5,
.cls-6 {
  stroke: #003a40;
  stroke-width: 4px;
}

.cls-5,
.cls-6 {
  stroke-linecap: round;
}

.cls-5 {
  opacity: 0.5;
}

.cls-6 {
  opacity: 0.16;
}

.cls-8 {
  font-size: 18px;
  font-family: ArialMT, Arial;
}

.cls-12,
.cls-8 {
  fill: #34747b;
}

.cls-9 {
  opacity: 0.69;
}

.cls-11 {
  fill: #66d8e5;
}

.cls-12 {
  font-size: 23px;
  font-family: SegoeUI-Semibold, Segoe UI;
  font-weight: 600;
}

.container-radio-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
}

.container-radio-inline .label,
.container-radio .label {
  color: #003a40;
  font-size: 1.2rem;
}

.radios-inline {
  clear: both;
  display: inline-block;
  margin-top: 0.2rem;
}

.radios-inline input {
  display: none;
}

.radios-inline input + label {
  float: left;
  padding: 0.1em 1em;
  cursor: pointer;
  border: 1px solid #2cbbc9;
  margin-right: -1px;
  color: #fff;
  background-color: #2cbbc9;
  opacity: 0.5;
}
.radios-inline input + label:first-of-type {
  border-radius: 33px 0 0 33px;
}
.radios-inline input + label:last-of-type {
  border-radius: 0 33px 33px 0;
}
.radios-inline input:checked + label {
  background-color: #2cbbc9;
  color: #fff;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.container-radio {
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.radios {
  clear: both;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.radios input {
  display: none;
}

.radios input + label {
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  float: left;
  padding: 0.2rem 0.6em;
  cursor: pointer;
  border: 1px solid #2cbbc9;
  color: #fff;
  background-color: #2cbbc9;
  opacity: 0.5;
}
.radios input + label {
  border-radius: 33px;
}

.radios input:checked + label {
  background-color: #2cbbc9;
  color: #fff;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#distance_form {
  width: 100%;
  text-align: center;
}

#distance_form input {
  width: 85%;
  border: 3px solid #78979a;
  height: 3rem;
  border-radius: 30px;
  color: #003a40;
  /* padding: 1rem; */
  margin-bottom: 0.1rem;
  font-size: 1rem;
}

#distance_form input {
  margin-bottom: 0.3rem;
}
#destination_step {
}
